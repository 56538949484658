const smoothScroll = () => {
	const scrollToTarget = (targetId) => {
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			targetElement.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};

	// Smooth scrolling for links
	document.addEventListener('DOMContentLoaded', function () {
		const smoothScrollLinks = document.querySelectorAll('a[href^="#"]');

		smoothScrollLinks.forEach((link) => {
			link.addEventListener('click', function (event) {
				event.preventDefault();
				const targetId = this.getAttribute('href').slice(1);
				scrollToTarget(targetId);
			});
		});
	});

	// Handle scrolling for hash in the URL on page load
	window.addEventListener('load', function () {
		if (window.location.hash) {
			const targetId = window.location.hash.slice(1);
			scrollToTarget(targetId);
		}
	});
};

export default smoothScroll;
